@use 'src/styles/vars' as v;

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: v.$color-white;
    width: 100vw;
    margin: .5em 0;

    @media (max-width: v.$breakpoint-mobile) {
        margin: 0;
        justify-content: flex-start;
        
        position: fixed;
        top: 0;
        z-index: 100;
        background-color: v.$color-white;
        height: 5em;

    }

    &__logo {
        display: flex;
        align-items: center;
        text-decoration: none;
        border-right: 2px solid v.$color-primary;

        @media (max-width: v.$breakpoint-mobile) {
            border-right: none;
            margin-left: 1em;
        }
    

        &__image {
            width: 70px;
            height: 100%;
            margin-right: 1em;

            @media (max-width: v.$breakpoint-mobile) {
                width: 40px;
            }
        }
        &__title {
            font-family: v.$ff-title;
            font-size: 2.5rem;
            color: v.$color-primary-dark;
            font-weight: 900;

            @media (max-width: v.$breakpoint-mobile) {
                font-size: 1.5rem;
            }
        }
        &__subtitle {
            font-family: v.$ff-title;
            transform: translateX(-1.1rem) translateY(1.4rem);
            font-size: 1rem;
            color: v.$color-primary-dark;
            font-weight: bolder;

            @media (max-width: v.$breakpoint-mobile) {
                display: none;
            }
        }
    }

    &__facebook {
        margin-left: 4rem;

        @media (max-width: v.$breakpoint-mobile) {
            margin-left: 1rem;
        }

        &__image {
            @media (max-width: v.$breakpoint-mobile) {
                width: 30px;
                margin-bottom: .5em;
            }   
        }
    }   
}