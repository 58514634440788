@use 'src/styles/vars' as v;

.agendaItems {
    margin: 1em;
    box-sizing: border-box;
    max-width: 250px;
    text-align: center;
    border: 1px solid v.$color-primary-dark;
    border-radius: 5px;
    padding: .4em;
    background-color: v.$color-primary-light;

    @media (max-width: v.$breakpoint-mobile) {
        border: none;
        width: 100%;
    }

    &__image {
        width: 100%;
        height: 200px;
        object-fit: contain;

        @media (max-width: v.$breakpoint-mobile) {
            height: 150px;
        }
    }

    &__title {
        font-size: 1rem;
        font-weight: bold;
        margin-top: 1.5em;

        @media (max-width: v.$breakpoint-mobile) {
            margin-top: .5em;
            font-size: .8rem;
        }
    }

    &__content {
        @media (max-width: v.$breakpoint-mobile) {
            margin-top: -.5em;
            font-size: .8rem;
        }
    }

    &__date {
        background-color: v.$color-white;
        width: 6em;
        height: 6em;
        display: block;
        border-radius: 5px;
        border: 2px solid v.$color-primary-dark;
        position: absolute;
        margin-top: -1.8em;
        margin-left: -1em;

        // first child
        & > div:first-child {
            background-color: v.$color-primary-dark;
            color: v.$color-white;
            font-weight: bold;
            font-size: 1.1rem;

        }

        // last child
        & > div:last-child {
            font-size: 1.1rem;
            margin-top: -.7em;
        }

        &__day {
            font-size: 1.9rem;
            font-weight: bold;

            @media (max-width: v.$breakpoint-mobile) {
                font-size: 1.1rem;
            }
        }

        @media (max-width: v.$breakpoint-mobile) {
            width: 3em;
            height: 4em;
            margin-top: -1.5em;
            margin-left: -.8em;

            & > div:first-child {
                font-size: .7rem;
            }

            & > div:last-child {
                font-size: .8rem;
                margin-top: -.5em;
            }
        }
    }
    

    &__place {
        font-size: .8rem;
        font-style: italic;

        @media (max-width: v.$breakpoint-mobile) {
            margin-top: -1em;
        }
    }

    &__button {
        display: block;
        margin: 0 auto .5em;
        background-color: v.$color-secondary-dark;
        border: none;
        padding: .5em 1em;
        border-radius: 5px;
        cursor: pointer;
        transition: all 1s ease-in-out;
        color: v.$color-white;

        &:hover {
            color: v.$color-primary-dark;
        }
    }
    
}
