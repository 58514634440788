@use 'src/styles/vars' as v;

.cardsContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @media(max-width: v.$breakpoint-mobile) {
        flex-direction: column;
        margin: 0;
        margin-right: 1rem;
    }
}