@use 'src/styles/vars' as v;


$sidebar-width: 200px;
$sidebar-gap: 10px;

.sidebar {
  position: fixed; 
  top: 60px;
  left: 0;
  bottom: 0;
  width: $sidebar-width;
  background-color: #f0f0f0;
  padding: 20px;

  a {
    display: block;
    margin-bottom: 10px;
    color: #000; 
    text-decoration: none;

    &:hover {
      color: #6b9c9f; // Couleur du texte en survol
    }
  }
}

// Styles pour le contenu principal à côté de la SideBar
.content {
  margin-left: $sidebar-width + $sidebar-gap; // Marge à gauche pour laisser de l'espace à la SideBar
}
