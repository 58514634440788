@use 'src/styles/vars' as v;

.services {
    padding-top: .5rem;
    display: flex;
    justify-content: center;
    background-color: v.$color-secondary-light;

    @media (max-width: v.$breakpoint-mobile) {
        flex-wrap: wrap;
        justify-content: space-evenly;
    }

    &__element {
        display: flex;
        align-items: center;
        margin: .5em;
        padding: .5em;
        border-bottom: 1px solid v.$color-primary;

        &:hover {
            border-bottom: 4px solid v.$color-primary;

            .services__element__icon {
                font-size: 1.9rem;
                font-weight: bold;

                @media (max-width: v.$breakpoint-mobile) {
                    font-size: 1.6rem;
                    font-weight: normal;
                }
            }

            .services__element__link {
                font-weight: bold;

                @media (max-width: v.$breakpoint-mobile) {
                    font-weight: normal;
                }
            }

            @media (max-width: v.$breakpoint-mobile) {
                border-bottom: none;
            }

        }

        @media (max-width: v.$breakpoint-mobile) {
            flex-direction: column;
            border-bottom: none;
            width: 32%;
            margin: .1em;
            padding: 0 .1em; 

        }

        &__icon {
            font-size: 1.6rem;
            margin-right: .3em;
            color: v.$color-primary;

            @media (max-width: v.$breakpoint-mobile) {
                font-size: 1.8rem;
            }

            &__ext {
                position: relative;
                top: 10px;
                left: -15px;
                color: v.$color-secondary-dark;
                
                @media (max-width: v.$breakpoint-mobile) {
                    position: relative;
                    top: -25px;
                    left: 14px;
                    margin-bottom: -23px;
                    color: v.$color-secondary-dark;
                }
            }

        }
        &__link {
            text-decoration: none;
            color: v.$color-black;
            font-size: .8rem;
            text-align: center;

            

            @media (max-width: v.$breakpoint-mobile) {
                font-size: .7rem;
            }
        }
    }
}

.servicesTitle {
    display: block;
    margin: 1em 4em !important;
    @media (min-width: v.$breakpoint-tablet) {
        display: none;
    }
}