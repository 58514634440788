@use 'src/styles/vars' as v;

.plan {
    text-align: center;
    &__image {
        width: 640px;
        height: 100%;
        object-fit: cover;

        @media (max-width: v.$breakpoint-mobile) {
            width: 90%;
        }
    }

    &__open {
        position: fixed;
        top: 0;
        left: 0; 
        right: 0; 
        bottom: 0;

        &__image {
            max-width: 100%; 
            max-height: 100%;
        }
    }

}