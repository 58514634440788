@use 'src/styles/vars' as v;

.photos {
   background-color: v.$color-secondary-light;
   padding: 1rem 0;
   margin: 1rem 0;
   text-align: center;

   &__container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 100%;
      

      &__element {
        width: 25%;
        padding: .2rem;
        
        &__image {
          width: 100%;
          height: 88%;
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
        }

        &__title {
          @media (max-width: v.$breakpoint-mobile) {
            font-size: .5rem;
            font-weight: 200 !important;
          }
        }
      }
   }
   
}

.full-screen-photo {
    position: fixed;
    top: 0;
    left: 0; 
    right: 0; 
    bottom: 0;
    display: flex;
    justify-content: center;
    z-index: 1000;

    &__image {
      @media (max-width: v.$breakpoint-mobile) {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
}