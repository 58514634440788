@use 'src/styles/vars' as v;

.list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    @media (max-width: v.$breakpoint-mobile) {
        flex-direction: column;
        padding: 1rem;
        text-align: center;
    }
    
    &__category {
        width: 50%;
        text-align: center;

        @media (max-width: v.$breakpoint-mobile) {
            width: 100%;
        }

        &__title {
            font-size: 2rem;
            font-weight: 600;
            margin: 2rem auto;
            color: v.$color-primary;
            border-bottom: 2px solid v.$color-primary-dark;
            padding-bottom: .5em;
            width: 80%;

            @media (max-width: v.$breakpoint-mobile) {
                font-size: 1.3rem;
            }
        }


        &__element {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5em;

        @media (max-width: v.$breakpoint-mobile) {
            margin-bottom: 0;
        }

            &__name {
                font-size: 1.2rem;
                font-weight: 600;
                margin: 1rem 0;

                @media (max-width: v.$breakpoint-mobile) {
                    font-size: 1rem;
                }
            }
            
            &__description {
                margin: -1rem auto .9rem auto;
                max-width: 500px;

                @media (max-width: v.$breakpoint-mobile) {
                    font-size: .8rem;
                    margin: -.5rem auto .5rem auto;
                }
            }
            &__contact {
                line-height: .6;

                @media (max-width: v.$breakpoint-mobile) {
                    font-size: .8rem;
                }
            }
            &__link {
                font-weight: 600;
                margin: -.2rem 0 0 0;
                color: black;
                text-decoration: none;
            }
        } 
    }
}