@use 'src/styles/vars' as v;

.addFile {
    width: 60%;
    min-width: 300px;
    margin: 30px auto;
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 0 0 5px #ccc;

    &__form {
        display: flex;
        flex-direction: column;
        //align-items: center;
        //justify-content: center;
        

        &__date {
            margin: 10px 0px;
        }


        &__button {
            display: block;
            margin: 10px auto;
            background-color: #6b9c9f;
            color: #fff;
            border: 1px solid #124f52;
            padding: 10px 20px;
            border-radius: 5px;
        }

        &__loader {
            margin: 10px auto;
            text-align: center;
        }

        &__success {
            color: green;
            font-weight: bold;
            text-align: center;
        }
    }

}