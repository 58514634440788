@use 'src/styles/vars' as v;

.navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    @media (max-width: v.$breakpoint-menu) {
        display: none;
    }

    &__item {
        font-size: .8rem;
        margin: 0 .8rem;
        text-transform: uppercase;
        padding-right: .8rem;
        border-right: 1px solid v.$color-primary;
        font-weight: 600;
        &:last-child {
            border-right: none;
        }
    }

    &__subitem {
        font-size: .9rem;
        font-weight: 600;

        &:hover {
            background-color: v.$color-primary-light;
        }

        @media (min-width: v.$breakpoint-desktop) {
            width: 380px;
        }
    }

    &__subsubitem {
        font-size: .9rem;
        text-transform: capitalize;
        &:hover {
            background-color: v.$color-primary-light;
        }

        @media (min-width: v.$breakpoint-desktop) {
            width: 380px;
        }
    }
}

// to override the color of the navigation__subitem
#navigation__subitem {
    color: #373737;
}