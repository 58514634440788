.mymodal {

    &__body {
        padding: 1em;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__image {
            display: block;
            margin: .5em auto;
            height: auto;
            width: 65%;
        }

        &__content {
            text-align: justify;
        }
    }

    &__footer {
        &__date {
            font-size: .7em;
        }
    }
}