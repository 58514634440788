@use 'src/styles/vars' as v;

.secondsNewsItems {
    margin: .5em;
    background-color: v.$color-secondary-light;
    border-radius: 5px;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .2);

    &__content {
        display: flex;
        padding: .5rem;

        &__image {
            max-width: 120px;
            max-height: 100%;
            border-radius: 5px 5px 0 0;
            object-fit: cover;
            margin-right: 1em;

            @media (max-width: v.$breakpoint-mobile) {
                max-width: 90px;
                max-height: 90px;
            }
        }

        &__right {
            width: 100%;

            &__title {
                font-size: 1.1rem;

                &:hover {
                    color: v.$color-primary-dark;
                    text-decoration: underline;
                }

                @media (max-width: v.$breakpoint-mobile) {
                    font-size: .9rem;
                }
            }

            &__content {
                font-size: .8rem;
                text-align: justify;

                @media (max-width: v.$breakpoint-mobile) {
                    font-size: .7rem;
                }
            }
        }

        &__footer {
            display: flex;
            justify-content: space-between;

            @media (max-width: v.$breakpoint-mobile) {
                flex-direction: column;
            }

            &__text {
                font-size: .7em;
            }
        }
            
    }
    
}
