@use 'src/styles/vars' as v;

.page {
    max-width: 1200px;
    margin: 5em auto 1em auto;

    @media (max-width: v.$breakpoint-mobile) {
        padding: 0;
    }

    &__title {
        font-size: 2rem;
        text-align: center;
        margin: 4rem 0 1rem 0;
        border-bottom: 2px solid v.$color-primary;
        padding-bottom: .5rem;

        @media (max-width: v.$breakpoint-mobile) {
            font-size: 1.2rem;
        }
    }

    &__content {
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: v.$breakpoint-mobile) {
            flex-direction: column;
            padding: 1rem;
            text-align: center;
        }

        &__image {
            max-width: 350px;
            height: 100%;
            margin-right: 2rem;
            border: 4px solid v.$color-primary;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

            @media (max-width: v.$breakpoint-mobile) {
                max-width: 65%;
                max-height: 390px;
                margin: 0 auto 1rem auto;
            }
        }

        &__container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            @media (max-width: v.$breakpoint-mobile) {
                flex-direction: column;
                padding: 1rem;
                text-align: center;
            }

            &__element {
                width: 33%;
                text-align: center;
                padding: 2em;

                @media (max-width: v.$breakpoint-mobile) {
                    width: 100%;
                    padding: 1em;
                }

                &__image {
                    width: 350px;
                    height: 100;
                    object-fit: contain;
                    margin-bottom: 1rem;

                    @media (max-width: v.$breakpoint-mobile) {
                        width: 250px;
                    }
                }
            }
            
        }
    }
}