@use 'src/styles/vars' as v;

.burgerMenu {

  &__button {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    font-size: 2em;
    border: none;
    background: none;
    cursor: pointer;
    z-index: 100;
    color: v.$color-primary;

    @media (max-width: v.$breakpoint-menu) {
      display: block;
    }
    
    @media (max-width: v.$breakpoint-mobile) {
      top: .4em;
      right: .1em;
    }
  }

  &__container {
    padding: 1em;
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-color: v.$color-white;
    border: none;
    cursor: pointer;
    animation: open 0.5s ease-in-out;

    &__item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #747474;
      margin-top: 1em;
      padding-bottom: .5em;

      &__link {
        font-size: 1.2em;
        text-transform: uppercase;
        font-weight: bold;
      }
      &__icon {
        font-size: 1.2em;
        margin-right: .5em;
        color: v.$color-primary-dark;
      }  
    }

    &__submenu {
      margin-top: 1em;

      &__subitem {
        display: flex;
        justify-content: space-between;
  
        &__link {
          font-size: .8em;
          text-transform: uppercase;
          &__text {
            text-decoration: none;
            color: black;
          }
        }
        &__icon {
          font-size: .9em;
          margin-right: .7em;
          color: v.$color-secondary-dark;
        }  
      }
    }

    &__subsubmenu {
      &__subsubitem {
        &__link {
          font-size: .8em;
          margin-left: 1.5em;
          &__text {
            text-decoration: none;
            color: v.$color-primary-dark;
          }
        }  
      }
    }

    

    &__close {
      display: flex;
      justify-content: flex-end;
      &__icon {
        font-size: 2.5em;
      }
    }
  }

}


 
