@use 'sass:math';
@import url('https://fonts.googleapis.com/css2?family=Poiret+One&display=swap');

$smallMenuWidth: 150px;

$gutter: 1rem;
$bg-color: #f2f2f2;
$ff-title: 'Poiret One', cursive;
$ff-menu-items: 'Open Sans', arial, sans-serif;
$menu-item-color: #0f618e;
$second-menu-item-color: #b1a01b;
$title-color: #1b5980;

$color-primary: #9ea690;
$color-primary-dark: #575f4a;
$color-primary-light: #9ea69083;

$color-white: #fff;
$color-black: #000;
//$color-secondary: #b97d33;
$color-secondary: #fceeda;
$color-secondary-dark: #c59a5d;
$color-secondary-light: #c59a5d4d;
$color-brown: #6D4B0A;


$menuWidth: 250px;
$small: 700px;
$halfGutter: math.div($gutter, 2);
$color: #209CEE;


$breakpoint-mobile: 450px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1200px;
$breakpoint-menu: 1000px;


.homeSectionTitle {
    text-align: center;
    color: $color-primary-dark;
    padding: .5em 1em;
    width: 50vw;
    border-top: 1px solid $color-secondary-dark;
    border-bottom: 1px solid $color-secondary-dark;
    margin: 2em auto 1em;
    text-transform: uppercase;
    font-size: 1.6rem;

    @media (max-width: $breakpoint-mobile) {
        font-size: .9rem;
    }
}

.homeSectionButton {
    background-color: $color-primary-dark;
    border: none;
    padding: .5em 1em;
    border-radius: 5px;
    cursor: pointer;
    transition: all 1s ease-in-out;
    margin: 4rem 0 0 0;
    font-size: 1.2em;
    color: $color-white;

    &:hover {
        background-color: $color-primary-dark;
    }

    &__link {
        text-decoration: none;
        color: $color-white;
    }

    @media (max-width: $breakpoint-mobile) {
        font-size: .9rem;
        margin: 0;
    }
}