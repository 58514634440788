@use 'src/styles/vars' as v;

.home {
    background-color: v.$color-white;
    margin-top: 2rem;

    &__image {
        display: block;
        width: 100vw;
        margin-top: 5em;

        @media (max-width: v.$breakpoint-mobile) {
            margin-top: 5em;
        }
    }
}