@use 'src/styles/vars' as v;

.news {
    background-color: v.$color-secondary-light;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 2em;


    &__content {
        max-width: 1200px;
        width: 100%;
    }

    &__articles {
    display: flex;
    justify-content: center;
    margin: 0 2em;

    @media (max-width: v.$breakpoint-mobile) {
        flex-direction: column;
        margin: 1em;
    }

        &__first {
            width: 50%;
            max-width: 500px;

            @media (max-width: v.$breakpoint-mobile) {
                width: 100%;
            }
        }

        &__others {
            width: 50%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            @media (max-width: v.$breakpoint-mobile) {
                width: 100%;
            }
        } 
    }
}