@use 'src/styles/vars' as v;

.keyData {
    background-color: v.$color-primary-light;
    padding-top: .1rem;

    &__container {
        display: flex;
        padding: 2rem 0;

        @media (max-width: v.$breakpoint-mobile) {
            flex-direction: column;
            padding: .3rem 0;
        }

        &__section {
            width: 50%;
            padding: 1rem;
            text-align: center;

            // first child
            &:first-child {
                border-right: 1px solid v.$color-primary-dark;
            }

            @media (max-width: v.$breakpoint-mobile) {
                width: 100%;
                border-right: none;
                padding: 0;
                margin-bottom: 1rem;
            }
            

            &__image {
                width: 100%;
                height: 100%;
                margin: .9rem 0;
            }

            &__text {
                font-size: 1.1rem;
                color: black;
                margin-top: 2rem;

                @media (max-width: v.$breakpoint-mobile) {
                    margin-top: 0rem;
                    font-size: .9rem;
                }
            }

            &__weather {
                max-width: 350px;
                margin: 0 auto;
                padding: .5rem 2rem;
                background-color: v.$color-primary-dark;
                border: 5px solid v.$color-primary-light;
                border-radius: 5px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;

                &__day {
                    &__first {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        color: white;
                        min-height: 150px;

                        @media (max-width: v.$breakpoint-mobile) {
                            min-height: 0;
                        }

                        &__icon {
                            width: 150px;

                            @media (max-width: v.$breakpoint-mobile) {
                                width: 100px;
                            }
                        }

                        &__infos {
                            width: 60%;
                        }
                    }

                    &__others {
                        color: white;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        &__icon {
                            width: 80px;
                            margin: -2em 0 -1.2em 0;

                            @media (max-width: v.$breakpoint-mobile) {
                                width: 60px;
                                margin: -1.5em 0 -1em 0;
                            }
                        }
                    }

                }
            }
        }
    }
}