@use 'src/styles/vars' as v;

.footer {
    text-align: right;
    &__container {
        background-color: v.$color-primary;
        display: flex;
        justify-content: space-between;
        padding: 1rem 3rem;
        margin-top: 2em;
        

        &__infos {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 40vw;
            font-size: 1.1rem;
            color: white;

            @media (max-width: v.$breakpoint-mobile) {
                width: 100%;
                font-size: .8rem;
                text-align: center;
            }

            &__title {
                font-size: 2rem;
                font-weight: 700;
                margin-bottom: 1rem;

                @media (max-width: v.$breakpoint-mobile) {
                    font-size: 1.2rem;
                    margin-bottom: 0;
                }
            }

            &__link {
                color: white;
            }
        }

        &__plan {
            height: 30vh; 
            width: 50vw;
            z-index: 1;

            @media (max-width: v.$breakpoint-mobile) {
                display: none;
            }
        }

        &__icons {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: v.$color-primary-dark;
            border-radius: 5px;
            padding: .5rem 1rem;
            margin-top: .5rem;

            @media (max-width: v.$breakpoint-mobile) {
                margin-top: 0;
                width: 100vw;
            }

            &__icon {
                width: 60px;
                height: 60px;
                margin: .2rem 1rem;

                @media (max-width: v.$breakpoint-mobile) {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }

    &__mentions {
        margin-right: 2em;
    }
}