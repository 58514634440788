@use 'src/styles/vars' as v;

.myCard {
    &__button {
        background-color: v.$color-secondary-dark;
        border: none;
        padding: .5em;
        border-radius: 5px;
        cursor: pointer;
        transition: all 1s ease-in-out;
        float: right;
        margin: 0 auto;

        &:hover {
            color: v.$color-white;
        }

        @media (max-width: v.$breakpoint-mobile) {
            font-size: .7em;
        }
    }
}
