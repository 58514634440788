@use 'src/styles/vars' as v;

.allNewsItem {
    width: 280px;
    margin: .5rem;

    @media (max-width: v.$breakpoint-mobile) {
        width: 92%;
        margin: 1em auto;
        border-bottom: 1px solid v.$color-secondary-dark;
    }

    &__last {
        @media (max-width: v.$breakpoint-mobile) {
            border-bottom: none;
        }
    }
    

    &__image {

        &__image {
            object-fit: cover;
            width: 100%;
            height: 180px;

            &:hover {
                width: 100%;
                height: 280px;

                @media (max-width: v.$breakpoint-mobile) {
                    height: 200px;
                    width: 100%;
                }
            }
        }
    }


    &__title {
        font-size: 1.2rem;
        font-weight: 400;
        margin: .7rem;
        text-align: center;
        height: 45px;

        &:hover {
            color: v.$color-primary-dark;
            text-decoration: underline;
        }

        @media (max-height: v.$breakpoint-tablet) {
            font-size: 1.2rem;
        }
    }

    &__description {
        margin-bottom: 1rem;
        font-size: .9rem;
        text-align: justify;
        margin: 0 1rem;
    }
}
