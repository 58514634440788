@use 'src/styles/vars' as v;

.agenda {
    margin: 4rem 0;
    text-align: center;

    &__content {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        @media (max-width: v.$breakpoint-mobile) {
            flex-direction: column;
            margin: 1em;
            align-items: center;
        }
    }

    
}