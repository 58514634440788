@use 'src/styles/vars' as v;

.calendar {

    &__row1 {
        display: flex;
        justify-content: center;

        @media (max-width: v.$breakpoint-mobile) {
            flex-direction: column;
        }

        &__month {
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            border: 5px solid v.$color-primary;
            //background-color: v.$color-primary-light;
            margin: 1rem 2em;
            width: 50%;
            max-width: 700px;
            height: 60vh;
            border-radius: 5px 5px 0 0;
            overflow-y: scroll;

            @media (max-width: v.$breakpoint-mobile) {
                width: 85%;
                height: 80vh;
            }


            &__title {
                font-size: 1.1em;
                padding: .3em;
                text-align: center;
                background-color: v.$color-primary;
                color: v.$color-white;
                font-weight: bold;
                width: 100%;

                @media (max-width: v.$breakpoint-mobile) {
                    width: 102%;
                }
            }
        }
    }
    &__row2 {
        display: flex;
        justify-content: space-between;
        margin: 0 4rem;

        &__button {
            display: block;
            margin: 0 auto;
            background-color: v.$color-primary-dark;
            font-size: 1.2em;
            border: none;
            padding: .5em 1em;
            border-radius: 5px;
            cursor: pointer;
            transition: all 1s ease-in-out;
            color: v.$color-white;

            &:hover {
                color: v.$color-primary-light;
            }
        }

        
    }
    
}