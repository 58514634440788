@use 'src/styles/vars' as v;

.conseilMunicipal {
    display: flex;
    flex-wrap: wrap;

    @media (max-width: v.$breakpoint-mobile) {
        flex-direction: column;
    }

    &__conseiller {
        text-align: center;
        width: 20%;
        margin-top: 2em;

        @media (max-width: v.$breakpoint-mobile) {
            width: 100%;
        }
        
        &__image {
            width: 220px;
            height: 100;
            object-fit: contain;
            border-radius: 50%;
            margin-bottom: 1.5em;

            @media (max-width: v.$breakpoint-mobile) {
                width: 50%;
                margin-bottom: .5em;
            }
        }

        &__name {
            @media (max-width: v.$breakpoint-mobile) {
                font-size: .8rem;
            }
        }

        &__role {
            @media (max-width: v.$breakpoint-mobile) {
                font-size: .7rem;
            }
        }

        &__responsabilite, &__commissions {
            margin-top: 4em;

            @media (max-width: v.$breakpoint-mobile) {
                margin-top: 2em;
                font-size: .7rem;
            }
        }
    }

    .conseilMunicipal__infos {
        margin-top: 4em;
        display: flex;
        background-color: v.$color-secondary-light;
        padding: 2em;
        margin-bottom: 1rem;

        @media (max-width: v.$breakpoint-mobile) {
            flex-direction: column;
            padding: 1em;
        }

        div:first-of-type {
            width: 60%;
            border-right: 1px solid v.$color-primary-dark;
            margin-right: 2em;

            @media (max-width: v.$breakpoint-mobile) {
                width: 100%;
                border-right: none;
                margin-right: 0;
            }
        }

        div:last-of-type {
            margin-left: 2em;

            @media (max-width: v.$breakpoint-mobile) {
                margin-left: 0;
            }
        }
    }
}