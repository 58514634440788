@use 'src/styles/vars' as v;

.allAgendaItems {
    margin: 1em;
    box-sizing: border-box;
    display: flex;

    &__day {
        font-size: 1.1rem;
        padding: .5em;
        border-radius: 5px 0 0 5px;
        color: v.$color-white;
        background-color: v.$color-primary;
        height: 150px;
        display: flex;
        align-items: center;
        font-weight: bold;

        @media (max-width: v.$breakpoint-mobile) {
            height: 90px;
            font-size: .9rem;
        }
    }
    

    &__image {
        height: 150px;
        margin-right: 1em;

        @media (max-width: v.$breakpoint-mobile) {
            height: 90px;
        }
    }

    &__title {
        font-size: 1.1em;
        font-weight: bold;
        color: v.$color-primary-dark;
    }

    &__place {
        font-size: .8rem;
        font-style: italic;
    }

    &__button {
        display: block;
        background-color: v.$color-primary-light;
        border: none;
        padding: .5em 1em;
        border-radius: 5px;
        cursor: pointer;
        transition: all 1s ease-in-out;
        color: v.$color-primary-dark;

        &:hover {
            color: v.$color-primary-dark;
        }
    }
    
}
