@use 'src/styles/vars' as v;

.firstNewsItem {
    margin: .5em;
    background-color: v.$color-secondary-light;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, .2);
    padding: 1em 0;
    
    &__container {
        background-color: transparent;
        border: none;

        &__image {
            max-width: 350px;
            height: 100%;
            margin: 1em auto;
            box-shadow: 0 0 5px 0 rgba(0, 0, 0, .2);

            @media (max-width: v.$breakpoint-mobile) {
                width: 150px;
                margin: .1em auto;
            }
        }

        &__title {
            font-size: 1.1rem;
            text-align: center;

            &:hover {
                color: v.$color-primary-dark;
                text-decoration: underline;
            }
        }

        &__content {
            font-size: .8rem;
            text-align: justify;

            &__footer {
                display: flex;
                justify-content: space-between;
                &__text {
                    font-size: .7em;
                    margin-left: 1.8em;
                }
            }
        }

        &__footer {
            background-color: transparent;
            border: none;
            font-size: .6em;
        }
    }

    
    
}
